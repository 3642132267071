import { AboutPageQuery } from "@/gatsby-graphql"
import { graphql, PageProps, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import tw, { styled } from "twin.macro"

import { BreadCrumbs, Layout, NextSEO, SEO } from "@/components"
import {
  Article,
  BackgroundImage,
  BaseLayer,
  Container, Headings, Section, Typography
} from "@/atoms"
import { Introduction } from "@/constants/text";

export type AboutPageProps = PageProps<AboutPageQuery>

const About: React.FC<AboutPageProps> = ({ data }) => {

  return (
    <Layout>
      {/* <SEO
        title={"税理士紹介"}
        description={
          "サラリーマンでも給料天引きの所得税から、住民税、自宅にかかる固定資産税から、買い物に行けば消費税、領収書には印紙税などなど… 身の回りにはいろいろな税が存在します。"
        }
      /> */}
      <NextSEO
        type="Article"
        title={"税理士紹介"}
        description={
          "サラリーマンでも給料天引きの所得税から、住民税、自宅にかかる固定資産税から、買い物に行けば消費税、領収書には印紙税などなど… 身の回りにはいろいろな税が存在します。"
        }
        url={`/about`}
        // TODO: add image
        breadCrumbs={[
          { title: "税理士紹介" },
        ]}
      />
      <BackgroundImage
        image={data.placeholderImage.childImageSharp.gatsbyImageData}
        header={<Headings variant="h2" as='h1'>税理士紹介</Headings>}
      />
      <Article maxWidth={'tablet'} css={{
        ...tw`space-y-12 pb-12 px-4`,
        '@tablet': { ...tw`px-0` }
      }}>
        <BreadCrumbs breadCrumbs={[{ title: "税理士紹介" }]} />
        <Section css={{
          ...tw`mx-auto flex flex-col items-center space-y-8`,
        }}>
          <Headings variant="h2" as='h1' >税理士よりご挨拶</Headings>
          <Typography prose={'base'}>
            {Introduction.self.text.map((ele, i) => <p key={i}>{ele}</p>)}
          </Typography>
          <div css={{ ...tw`flex flex-col items-center justify-center space-y-2` }} className='no-prose'>
            <div css={{
              ...tw`w-32`,
              '@tablet': {
                ...tw`w-60`
              }
            }}>
              <GatsbyImage
                image={data.profile.childImageSharp.gatsbyImageData}
                alt="profile"
                imgStyle={{ borderRadius: '50%' }}
              />
            </div>
            <span>{Introduction.self.name}</span>
          </div>
        </Section>
      </Article>
    </Layout >
  )
}

export default About

export const data = graphql`
    query AboutPage {
      profile: file(relativePath: { eq: "profile.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            formats: [WEBP]
            placeholder: BLURRED
          )
        }
      }
        placeholderImage: file(relativePath: { eq: "image4.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [WEBP]
            )
          }
        }
    }
  `
